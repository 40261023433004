.create-comment-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}



.textarea-comment {
    width: 25vw;
    height: 65px;
    resize: none;
    font-size: 15px;
}

.under-comment-info {
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin-bottom: 80px;
    margin-top: 10px;
}

.counter-char {
    font-size: 17px;
    color: rgb(107, 107, 107);
}

.comment-submit-btn {
    width: 80px;
    margin-bottom: 12px;
    border-width: 0;
    height: 30px;
    background-color: rgb(18, 143, 220);
    color: white;
    font-weight: 550;
    border-radius: 5px;
}

.comment-submit-btn:hover {
    cursor: pointer;
    background-color: rgb(58, 167, 235);
}
