.album-form-container {
  background-color: white;
  max-height: 1200px;
  width: 352px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
}

.album-form-photo-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 3px;
}

.album-form-photo-imgs {
  height: 70px;
  width: 80px;
  object-fit: cover;
}

.album-form-photo-btn:hover {
  cursor: pointer;
}

.select-photos-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
}

.create-edit-album-title {
  font-size: 25px;
  margin-top: 5px;
  font-weight: 600;
}

.errors-btn {
  width: 80px;
  margin-bottom: 12px;
  border-width: 0;
  height: 40px;
  background-color: rgba(161, 159, 159, 0.445);
  color:  white;
  font-weight: 550;
  border-radius: 5px;
}

.img-border-selected {
  border: 2px solid red;
  height: 70px;
  width: 80px;
}

.notSelected {
  border: 0;
}

.no-photos-uploaded-ctn {
  font-size: 18px;
  padding: 10px;
}

.no-photos-up-link {
  font-weight: 600;
  color: black;
  text-decoration: none;
}

.no-photos-up-link:hover {
  text-decoration: underline;
}
