.tagged-pg-whole {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 1px;
}

.tagged-pg-title {
    font-size: 30px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.tagged-pg-photo-img {
    height: 250px;
    width: 376px;
    margin-top: 10px;
    margin-right: 4px;
    margin-left: 4px;
}

.tagged-photos-area-ctn {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

