.profile-pg-whole {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.profile-pg-background-top {
    background-image: url("../../assets/background-default.jpg");
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 250px;
}

.profile-info-container {
    display: flex;
    width: 80%;
    height: 250px;
    margin-left: 10%;
    margin-right: 10%;
    justify-content: flex-start;
    align-items: center;
}

.prof-img {
    border-radius: 50%;
    height: 120px;
    width: 120px;
    object-fit: cover;
    margin-top: 80px;
}

.prof-full-name-btn-container {
    color: white;
    align-items: center;
    display: flex;
    margin-top: 110px;
    margin-left: 20px;
}

.prof-fullname {
    font-size: 40px;
    margin-right: 20px;
    font-weight: 100;
}

.pro-btn {
    background-color: transparent;
    width: 45px;
    height: 35px;
    color: white;
    border-style: solid;
    border-color: white;
    border-radius: 5px;
    font-size: 20px;
}

.pro-btn:hover {
    cursor: pointer;
    background-color: white;
    color: black;
}

.prof-username-followers-container {
    color: white;
    display: flex;
    align-items: center;
    margin-left: 20px;
    margin-top: 20px;
    font-size: 18px;
}

.fa-circle{
    margin-right: 10px;
    font-size: 6px;
}

.prof-info-text {
    margin-right: 10px;
}


.album-nav-container {
    display: flex;
    align-items: center;
    margin-left: 10%;
    margin-right: 10%;
    height: 50px;
    background-color: white;
}

.albun-nav-label {
    font-size: 25px;
    padding-bottom: 10px;
    padding-top: 5px;
    border-bottom: 3px solid rgb(0,145,220);
}


.albums-container-bg {
    background-color: rgb(243,245,246);
    width: 100%;
    height: calc(100% - 400px);
    margin: 0;
}

.albums-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12.35px;
}

.albums-ctn-page {
    width: 81%;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 30px;
    height: calc(100vh - 350px);
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
}

.add-album-container {
    display: flex;
    justify-content: flex-end;
    width: 80.7%;
    margin-left: 10%;
    margin-right: 10%;
    font-size: 25px;
}

.add-album-link {
    display: flex;
    margin-top: 10px;
    text-decoration: none;
    color: rgb(142,147,150);
}

.add-album-link:hover {
    color: black;
}

.fa-folder-plus {
    margin-right: 10px;
}

.no-albums-msg {
    font-size: 30px;
    margin-top: 200px;
}

.no-albums-ctn {
    width: 80%;
    height: calc(100vh - 439px);
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    justify-content: center;
}


.album-card-margins {
    display: flex;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
}
