.album-pg-whole {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 100px);
    background-color: rgb(243,245,246);
}

.album-pg-background-top {
    background-color: rgba(68, 68, 68, 0.308);
    width: 80%;
    margin-right: 10%;
    margin-left: 10%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 150px;
    position: absolute;
    top: 0px;
}

.album-banner-title-descr {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.album-banner {
    margin-left: 10%;
    margin-right: 10%;
    width: 80%;
    height: 35vh;
    object-fit: cover;
    object-position: center;
}

.album-banner-info-title {
    font-size: 40px;
    font-weight: 600;
    color: rgb(255,255,255);
    margin-bottom: 20px;
}

.album-banner-info-description {
    font-size: 20px;
    font-weight: 600;
    color: rgb(255,255,255);
}

.album-banner-info-credits {
    font-size: 18px;
    font-weight: 500;
    color: rgb(255,255,255);
}

.album-pg-bottom-background {
    background-color: rgb(243,245,246);
    width: 100%;
    height: 100%;
}

.album-photos-btm-ctn {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: 10%;
    margin-right: 10%;
    width: 80%;
    background-color: rgb(243,245,246);
}

.album-pg-photo-img {
    height: 250px;
    width: 376px;
    margin-top: 10px;
    margin-right: 4px;
    margin-left: 4px;
}

.empty-album-ctn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 28px;
}

.empty-album-msg {
    margin-right: 20px;
}

.add-to-empty-alb-btn {
    font-size: 28px;
    color: rgb(142,147,150);
}

.add-to-empty-alb-btn:hover {
    cursor: pointer;
    color: black;
}
