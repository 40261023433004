.up-photo-bg {
  background-color: rgb(44, 44, 44);
  height: calc(100vh - 50px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-form-container {
  background-color: white;
  height: 350px;
  width: 352px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
}

.up-photo-form {
  height: 100%;
  width: 311px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.up-error-ctn {
   height: 40px;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.required-label {
  color: red;
}

.up-edit-delete-photo-title {
  font-size: 25px;
  margin-top: 5px;
  font-weight: 600;
}

.up-error-msg {
  color: red;
    font-weight: 600;
    font-size: 13px;
}

.up-input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60px;
}

.up-photo-modal-input {
  width: 300px;
  margin-bottom: 10px;
  font-size: 18px;
  padding-left: 5px;
}

.up-photo-btn-containers {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
  align-items: center;
}

.photo-up-edit-label {
  font-weight: 600;
}

.up-photo-btn {
  width: 80px;
  margin-bottom: 12px;
  border-width: 0;
  height: 40px;
  background-color: rgb(18, 143, 220);
  color: white;
  font-weight: 550;
  border-radius: 5px;
}

.up-photo-btn:hover {
  cursor: pointer;
  background-color: rgb(58, 167, 235);
}

.up-photo-cancel {
  font-size: 18px;
  font-weight: 500;
}

.up-photo-cancel:hover {
  cursor: pointer;
}
