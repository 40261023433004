.del-comment-ctn {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90%;
    justify-content: space-between;
}

.del-comment-msg {
    display: flex;
    justify-content: center;
    font-size: 16px;
}

.del-comment-btn-ctn {
    display: flex;
    justify-content: flex-end;
    width: 350px;
}

.del-comment-cancel {
    margin-right: 10px;
    background-color: rgb(151,158,162);
    border-width: 0;
    color: rgb(254,254,254);
    height: 29px;
    width: 83px;
    border-radius: 5px;
}

.del-comment-cancel:hover {
    cursor: pointer;
    background-color: rgba(151, 158, 162, 0.815);
}

.delete-comment-btn {
    background-color: rgb(226,41,43);
    color: white;
    border-width: 0;
    height: 29px;
    width: 83px;
    border-radius: 5px;
}

.delete-comment-btn:hover {
    cursor: pointer;
    background-color: rgba(226, 41, 44, 0.808);
}
