.splash-page-background {
  display: flex;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 100px);
  object-fit: cover;
}

.splash-page-background-container {
  display: flex;
  min-width: 100%;
  justify-content: center;
  align-items: center;
}

.splash-page-text-container {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 700px;
  height: 400px;
}

.splash-text {
  color: white;
  font-family: Proxima Nova, helvetica neue, helvetica, arial, sans-serif;
  font-size: 26px;
  font-weight: 600;
}

.splash-title {
  font-size: 54px;
  margin-bottom: 50px;
  font-weight: 600;
  color: white;
  font-family: Proxima Nova, helvetica neue, helvetica, arial, sans-serif;
}

.splash-btn-link {
  margin-top: 30px;
}

.get-started-btn {
  margin-top: 50px;
  font-family: Proxima Nova, helvetica neue, helvetica, arial, sans-serif;
  color: black;
  font-size: 24px;
  font-weight: 600;
  padding: 20px;
  background-color: white;
  text-decoration: none;
  border-radius: 5px;
}

.get-started-btn:hover {
  color: rgba(99, 99, 99, 0.705);
}
