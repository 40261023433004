.footer-wrapper {
    background-color: rgb(32,31,35);
    height: 50px;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-tech-stack {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    color: rgba(255, 255, 255, 0.479);
    font-family: Proxima Nova,helvetica neue,helvetica,arial,sans-serif;
    font-size: 15px;
}

.flickr-link {
    text-decoration: none;
    color: white;
}

.flickr-link:hover {
    text-decoration: underline;
}

.about-links-icons {
    width: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.about-icons {
    height: 25px;
    transition: transform 0.5s ease-in-out;
}

.about-icons:hover {
    transform: scale(1.3);
}


@media screen and (min-width: 1009px) and (max-width: 1471px){
    .footer-tech-stack {
        font-size: 10px;
    }
}

@media screen and (min-width: 500px) and (max-width: 1010px){
    .footer-tech-stack {
        font-size: 7px;
    }
}
