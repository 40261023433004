.edit-photo-modal {
  display: flex;
  flex-direction: column;
  width: 367px;
  height: 300px;
  margin: 15px;
}

.edit-photo-top {
  width: 100%;
  height: 40px;
}

.edit-del-photo-title {
  font-weight: 600;
  font-size: 25px;
  height: 30px;
}

.edit-photo-modal-input {
  height: 37px;
  width: 360px;
  margin-bottom: 10px;
  padding-left: 5px;
  font-size: 16px;
}

.edit-photo-btn-containers {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.edit-photo-error-container {
    width: 100%;
    padding: 0;
    height: 6px;
}

.edit-modal-errors {
    list-style: none;
    color: red;
    font-weight: 600;
    font-size: 13px;
}

.edit-photo-btn {
  border-width: 0;
  height: 40px;
  width: 70px;
  background-color: rgb(18, 143, 220);
  color: white;
  font-weight: 550;
  border-radius: 5px;
}

.edit-photo-btn:hover {
  cursor: pointer;
  background-color: rgb(58, 167, 235);
}

.edit-photo-cancel {
  font-size: 18px;
  font-weight: 500;
}

.edit-photo-cancel:hover {
  cursor: pointer;
}
