.edit-del-comment-ctn {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    /* background-color: rgb(33,33,36);
    color: white; */
}

.edit-del-comment-title {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 600;
}

.edit-del-comment-form {
    display: flex;
    flex-direction: column;
}

.edit-comment-label {
    margin-bottom: 10px;
    font-weight: 600;
    margin-top: 40px;
}

.comment-textarea {
    width: 300px;
    height: 70px;
    resize: none;
}

.edit-counter-char {
    position: static;
}

.edit-del-comment-btns-ctn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
}

.edit-comment-btn {
    width: 80px;
    border-width: 0;
    height: 30px;
    background-color: rgb(18, 143, 220);
    color: white;
    font-weight: 550;
    border-radius: 5px;
}

.edit-comment-btn:hover {
    cursor: pointer;
    background-color: rgb(58, 167, 235);
}
.cancel-edit-comment {
    font-weight: 600;
}

.cancel-edit-comment:hover {
    cursor: pointer;
}
