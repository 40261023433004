.explore-pg-wrapper {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.explore-title-container {
  display: flex;
  width: 80%;
  background-color: white;
}

.explore-title {
  display: flex;
  justify-content: flex-start;
  font-size: 24px;
  padding-bottom: 15px;
  padding-top: 15px;
  border-bottom: 3px solid black;
  margin: 0;
}

.explore-pg-imgs {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  justify-content: flex-start;
  align-content: flex-start;
  margin: 0px;
  padding: 0px;
  width: 80%;
  gap: 2px;
}

.explore-pg-background {
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0;
  background-color: rgb(243, 245, 246);
  padding-top: 1px;
}

.explore-cards-ctn {
  display: flex;
  width: 100%;
  height: calc(100% - 161px);
  justify-content: center;
  margin-bottom: 50px;
}

.photo-img {
  /* object-fit: contain;
    max-width: 400px;
  max-height: 212px; */
  transition: transform 0.5s ease-in-out;
  height: 259px;
}

.photo-img:hover {
  /* max-width: 400px; */
  transform: scale(1.1);
}

.photo-img-even {
  max-width: 381px;
}

.photo-img-odd {
  max-width: 381px;
}

.photo-detail-link {
  margin: 0;
  padding: 0;
}
