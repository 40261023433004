.photo-detail-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.single-photo {
    height: 700px;
    width: 1000px;
}

.photo-detail-img-background {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(33,33,36);
    padding-top: 40px;
    padding-bottom: 10px;
}

.photo-detail-img-container {
    display: flex;
    justify-content: center;
}

.photo-detail-options {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-right: 100px;
    margin-top: 10px;
}

.photo-detail-btns {
    font-size: 30px;
    margin-right: 10px;
    color: rgba(255, 255, 255, 0.315);
    background-color: rgb(33,33,36);
    border-width: 0;
}

.photo-detail-bottom-ctn {
    height: 50%;
}

.photo-detail-btns:hover {
    cursor: pointer;
    color: rgba(255, 255, 255, 0.842);
}


.profile-pic-photo-info-ctn {
    display: flex;
    width: 60vw;
    padding-top: 20px;
}

.default-photopic {
    border-radius: 50%;
    height: 100px;
    width: 100px;
}

.owner-photo-info {
    display: flex;
    width: 60vw;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 10px;
    border-bottom: 1px solid black;
}

.photo-pg-comments-wrapper {
    height: 30vh;
    width: 30vw;
    /* display: flex; */
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.comments-tags-info-ctn {
    display: flex;
    height: auto;
    margin-bottom: 150px;
}

.comments-title-ctn {
    width: 30vw;
    display: flex;
    justify-content: center;
    text-decoration: underline;
    font-size: 20px;
    border-bottom: 1px solid black;
}

.owner-name {
    font-size: 20px;
    font-weight: 600;
}

.owner-description {
    font-size: 16px;
}

.comments-area {
    margin-top: 20px;
}

.photos-pg-placeholder-ctn {
   margin-left: 20px;
   margin-top: 20px;
}

.tags-area {
    font-size: 18px;
}

.footer-pg-ctn {
    margin-top: 20px;
}

.tags-photo-title {
    color: rgb(0,126,184);
    font-size: 20px;
}

.tags-photo-title-create-ctn {
    display: flex;
    justify-content: space-between;
    width: 29vw;
}

.tag-create-icon-ctn {
    display: flex;
    align-items: center;
}

.tag-create-icon-btn {
    background-color: transparent;
    border: 0;
    color: rgb(165, 165, 165);
    font-size: 17px;
}

.tag-create-icon-btn:hover {
    cursor: pointer;
    color: black;
}

.tags-area {
    display: flex;
}
