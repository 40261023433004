.modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-background {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.548);
}

.modal-content {
    position: absolute;
    background-color: white;
    width: 400px;
    height: 290px;
    border-radius: 10px;
    margin-left: 15px;
    margin-right: 15px;
}
